<template>
  <v-data-table
    must-sort
    fixed-header
    :loading="loading"
    :headers="headers"
    :items="jobs"
    no-data-text="No pending jobs"
    item-key="id"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 5, 25, 50, 100 ],
      itemsPerPageText: `Jobs ${ $t('per page') }:`,
    }"
  >
    <!-- <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
      </v-row>
    </template> -->
    <template #item.queue="{ item }">
      <v-chip
        small
        v-text="item.queue"
      />
    </template>
    <template #item.payload="{ item }">
      {{ JSON.parse(item.payload).displayName }}
    </template>
    <template #item.failed_at="{ item }">
      <span class="text-caption">
        {{ item.failed_at | moment('L LT') }}
      </span>
    </template>
    <template #item.actions="{ item }">
      <v-menu
        bottom
        right
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            small
            ripple
            icon
            plain
            v-on="on"
          >
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list
          class="py-0"
          dense
        >
          <v-list-item
            @click="retryJob(item)"
          >
            <v-list-item-action class="mr-4">
              <v-icon
                key="fal fa-retry fa-fw"
                small
              >
                fal fa-arrows-repeat fa-fw
              </v-icon>
            </v-list-item-action>
            <v-list-item-content><v-list-item-title>Retry</v-list-item-title></v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="deleteJob(item)"
          >
            <v-list-item-action class="mr-4">
              <v-icon
                key="fal fa-trash fa-fw"
                small
              >
                fal fa-trash fa-fw
              </v-icon>
            </v-list-item-action>
            <v-list-item-content><v-list-item-title>Delete</v-list-item-title></v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template x#footer.prepend>
      <v-select
        v-model="interval"
        dense
        :items="[
          15,30,60,120
        ]"
        class="v-data-footer__select ml-2 mt-4"
      >
        <template #prepend>
          <span class="text-caption mt-n4">Refresh every</span>
        </template>
        <template #append-outer>
          <span class="text-caption mt-n4">seconds</span>
          <v-progress-circular
            class="ml-2 mt-n4"
            color="grey darken-1"
            :rotate="-90"
            :size="20"
            :width="3"
            :value="100 - (100 * (time_left / (interval -1)))"
          />
        </template>
      </v-select>
      <v-spacer />
    </template>
  </v-data-table>
</template>
<script>
  export default {
    components: {
      // SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
    },
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'Jobs',
    },
    data () {
      return {
        right: false,
        loading: false,
        options: {
          filter: this.$route.query,
          search: null,
          sortBy: ['failed_at'],
          sortDesc: [true],
        },
        totalItems: null,
        headers: [
          {
            value: 'id',
            text: 'ID',
          },
          {
            value: 'queue',
            text: 'Queue',
          },
          {
            value: 'payload',
            text: 'Job',
          },
          {
            value: 'failed_at',
            text: 'Failed At',
          },
          {
            sortable: false,
            align: 'right',
            value: 'actions',
          },
        ],
        jobs: [],
        timer: null,
        time_counter: null,
        interval: 30,
        time_left: this.interval - 1,
      }
    },
    watch: {

      options: {
        deep: true,
        handler () {
          this.fetchJobs()
        },
      },
      interval () {
        this.startTimer()
      },
    },
    mounted() {
    //   this.startTimer()
    },
    methods: {
      startTimer () {
        clearInterval(this.timer)
        this.timer = setInterval(this.fetchJobs, this.interval * 1000)

        clearInterval(this.time_counter)
        var counter = this.interval - 1
        this.time_counter = setInterval(function() {
          if (counter < 1) {
            counter = this.interval - 1
          }
          this.time_left = counter--
        }.bind(this), 1000)
      },
      fetchJobs () {
        this.loading = true
        const promise = this.axios.get('admin/jobs/failed', {
          params: {
            ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'desc' : 'asc',
              search: this.options.search,
            },
            ...this.$route.query,
          },
        })

        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchJobs()
            return
          }
          this.totalItems = response.data.total
          this.options.page = response.data.current_page
          this.options.itemsPerPage = Number(response.data.per_page)
          this.jobs = response.data.data
        }).catch(error => {
          this.$toast.error(error)
        }).finally(() => {
          this.loading = false
        })
      },
      retryJob (item) {
        this.axios.post('admin/jobs/failed/' + item.id)
      },
      deleteJob (item) {
        this.axios.delete('admin/jobs/failed/' + item.id)
      },
    },
    beforeRouteLeave (to, from, next) {
      clearInterval(this.timer)
      next()
    },
  }
</script>
